nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    background-color: #ECE3CE;
    position: sticky;
}

nav a {
    color: black;
    text-decoration: none;
    padding-left: 1.5rem;
}

nav ul {
    list-style: none;
    display: flex;
}

nav ul li {
    padding-left: 1rem;
}

nav ul li a {
    padding: 10px 15px;
    transition: all linear 150ms;
    border-radius: 10px;
}

nav ul li a:hover {
    background-color: #3A4D39;
    color: white;
}


nav div {
    display: none;
    position: absolute;
    right: 0.5rem;
    top: 1.5rem;
}




@media (max-width: 700px ) {
  nav ul {
    display: none
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .open {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0;
    
  }
  nav div {
    display: flex; 
  }

  nav ul li a {
    font-weight: 600;
    font-size: 30px;
    
  }
}