
.resume-con {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
}

.img {
    width: 60%;
    position: relative;
    top: 1.5rem
}

