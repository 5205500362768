.portfolio {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.port-con {
    display: flex;
    height: 90%;
    justify-content: space-evenly;
}

.port-link {
    color: black;
    width: 25%;
    background-color: #ECE3CE;
    border-radius: 20px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    
   
    
}

.port {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

}

.port div {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.port-link ul {
    list-style: none;
    padding: 0;
}

 .port-link p {
    width: 90%;
 }

.port-link:hover {
    background-color: #3A4D39;
    color: white;
    transition: all 200ms linear;
}

.port-img {
    width: 200px;
}

@media (max-width: 850px) {
    .portfolio {
        height: unset;
    }

    .port-con {
        flex-direction: column;
        align-items: center;
    }

    .port-link {
        width: 85%;
        margin-bottom: 15px;
        padding: 0;
    }
}