.about-con {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 3rem 1.5rem;
    height: 100vh;
    line-height: 1.5rem;

}

.about-con p {
    width: 80%;
    font-size: 1.5rem;
   font-weight: 600 ;
   line-height: 2rem
}
.about-con h1 {
    font-weight: 700;
    font-size: 3rem;
    text-decoration: underline;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    text-align: center;
}

.about-img {
    height: 450px;
    border-radius: 100%;
}

.git-link {
    margin-top: 1rem;
    padding: 1.25rem 1.5rem;
    background-color: #ECE3CE;
    font-size: 3rem;
    color: black;
    border: solid 2px #3A4D39;
    text-decoration: none;
    border-radius: 10px;
}

.git-link:hover {
    background-color: #3A4D39;
    color: white;
    transition: all 150ms linear;
}

@media (max-width: 1000px) {
    .about-con {
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        height: 100%;
    }

    .about-img {
        height: 350px
    }

    .text {
        width: 100%;
    }
}